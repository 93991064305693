import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";
import Divider from "components/General/Divider";
import Spacer from "components/General/Spacer";
import SmallButton from "components/General/SmallButton";
import { ArrowIcon, DeleteIcon, Done } from "constants/Svgs";

import Input from "./Input";
import Button from "./Button";
import { StyledLink } from "../HomeWork/Sources";
import findCitation from "lib/citation/findCitation";
import axiosErrorHandler from "lib/utils/axiosErrorHandler";
import CircularLoader from "./CircularLoader";
import { CitationType } from "types/citation";
import parseCitationOutput from "lib/citation/parseCitationOutput";
import { Editor } from "@tiptap/react";
import addCitation from "lib/citation/addCitation";
import getDomainName from "lib/utils/getDomainName";
import { useSlashCommandPopover } from "hooks/useSlashCommandPopover";
import useSettings from "hooks/user/useSettings";
import { useRefreshUser } from "hooks/user/useUser";

interface FindeZitateProps {
  editor: Editor;
  disabled?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  isSlashCommand?: boolean;
}

export default function FindeZitate({
  editor,
  disabled,
  children,
  onClose,
  isSlashCommand,
}: FindeZitateProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [citations, setCitations] = useState<CitationType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { settings } = useSettings();
  const refreshUser = useRefreshUser();

  const { position, contentRef, updatePosition } = useSlashCommandPopover(
    editor,
    isSlashCommand
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCancelFind = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the request when popover closes
    }
    abortControllerRef.current = new AbortController();
    setLoading(false);
    setError(false);
  };

  const handleClose = () => {
    handleCancelFind();
    setIsOpen(false);
    if (onClose) onClose();
    setSearch("");
    setCitations([]);
  };

  useEffect(() => {
    if (isSlashCommand) {
      setIsOpen(true);
    }
  }, [isSlashCommand]);

  useEffect(() => {
    return () => {
      // Cancel the request when the component unmounts
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleFindCitation = async () => {
    if (!loading) {
      try {
        setError(false);
        if (!search) throw new Error("Please provide search query");
        setCitations([]);
        setLoading(true);
        if (abortControllerRef.current) {
          abortControllerRef.current.abort(); // Abort any ongoing request
        }
        abortControllerRef.current = new AbortController(); // Create a new controller for this request
        let resp = await findCitation(
          search,
          settings.useBeckOnline ? "beck" : "rechtssprechung",
          abortControllerRef.current.signal
        );

        if (resp.output) {
          const citationResults = parseCitationOutput(resp.output);
          // console.log(citationResults);
          setCitations(citationResults);
          if (citationResults.length === 0 && loading) {
            setError(true);
          }
          setLoading(false);
          refreshUser();
        }
      } catch (e: unknown) {
        axiosErrorHandler(e, true);
        if (e instanceof Error && e.message !== "canceled") setError(true);
        setLoading(false);
      }
    }
  };

  const handleAddCitation = (source: string, content: string, url?: string) => {
    addCitation(editor, { source, content, url });
    handleClose();
  };

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (open) {
          handleOpen();
        } else {
          handleClose();
        }
      }}
    >
      {children ? (
        <Popover.Trigger asChild onClick={handleOpen}>
          {children}
        </Popover.Trigger>
      ) : (
        <Popover.Trigger asChild>
          <span style={{ display: "none" }} onClick={handleOpen} />
        </Popover.Trigger>
      )}
      <Popover.Portal>
        <Popover.Content
          ref={contentRef}
          style={{
            zIndex: 10000,
            ...(isSlashCommand
              ? {
                  position: "absolute",
                  left: `${position.x}px`,
                  top: `${position.y}px`,
                }
              : {}),
          }}
          sideOffset={16}
          side="bottom"
          align="start"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            if (isSlashCommand) {
              updatePosition();
            }
          }}
        >
          <Content>
            <Row>
              <InputWrapper>
                <Input
                  isBottomBorderRadius={!loading && !error}
                  disabled={disabled || loading}
                  value={search}
                  onChange={setSearch}
                  isExpandable
                  placeholder="Suche Zitat..."
                />
                <StatusBar
                  brand={
                    settings.useBeckOnline ? "BeckOnline" : "Rechtssprechung"
                  }
                  isError={error}
                  isLoading={loading}
                  onCancel={handleCancelFind}
                  onNewSearch={handleFindCitation}
                />
              </InputWrapper>
              <Button
                onClick={handleFindCitation}
                type="submit"
                square
                disabled={loading}
              >
                {loading ? <CircularLoader loading={loading} /> : <ArrowIcon />}
              </Button>
            </Row>

            {citations.length > 0 && (
              <>
                <Bubble>
                  {citations.map((citation, i) => (
                    <React.Fragment key={i}>
                      <SourceDisplay>{citation.source}</SourceDisplay>
                      <CitationDisplay>{citation.content}</CitationDisplay>
                      <Divider noMargin color="#363433" />
                      <Spacer height={8} />
                      <Row>
                        <StyledLink
                          item={{
                            source: citation.url
                              ? getDomainName(citation.url)
                              : "Kein Link",
                            url: citation.url || null,
                          }}
                          index={0}
                        />
                        <SmallButton
                          onClick={() =>
                            handleAddCitation(
                              citation.source,
                              citation.content,
                              citation.url
                            )
                          }
                        >
                          <Done />
                        </SmallButton>
                        <SmallButton>
                          <DeleteIcon />
                        </SmallButton>
                      </Row>
                      {i !== citations.length - 1 && (
                        <>
                          <Divider noMargin color="#363433" />
                          <Spacer height={8} />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </Bubble>
                <FalseCitation>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.38686 11.2856H9.924C10.7666 11.2856 11.3109 10.6599 11.3109 9.89278C11.3109 9.66478 11.2509 9.42992 11.1309 9.21306L6.85457 1.57335C6.73732 1.35736 6.56395 1.17703 6.35274 1.05137C6.14154 0.925704 5.90033 0.859375 5.65457 0.859375C5.40881 0.859375 5.16761 0.925704 4.9564 1.05137C4.7452 1.17703 4.57183 1.35736 4.45457 1.57335L0.185143 9.21306C0.0651429 9.43592 0 9.66478 0 9.89278C0 10.6599 0.544286 11.2856 1.38771 11.2856H1.38686ZM5.66314 7.54249C5.34171 7.54249 5.15743 7.36249 5.14629 7.04192L5.07 4.32135C5.05886 3.98363 5.29886 3.75049 5.658 3.75049C6.006 3.75049 6.26143 3.98963 6.25029 4.32649L6.16372 7.03678C6.15257 7.36849 5.97343 7.54249 5.66314 7.54249ZM5.66314 9.42478C5.29286 9.42478 4.98857 9.15906 4.98857 8.79906C4.98857 8.43992 5.28771 8.17335 5.66314 8.17335C6.03343 8.17335 6.33172 8.43992 6.33172 8.79906C6.33172 9.16335 6.02743 9.42478 5.66314 9.42478Z"
                      fill="white"
                      fillOpacity="0.282"
                    />
                  </svg>
                  Found citations can be false - check them.
                </FalseCitation>
              </>
            )}
          </Content>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function StatusBar({ isLoading, isError, brand, onCancel, onNewSearch }) {
  const statusData = useMemo(() => {
    if (isError) {
      return {
        bg: "#2f2026",
        border: "#9D174D33",
        actionText: "Neu starten",
        actionTextColor: "#D90058",
      };
    }
    if (isLoading) {
      return {
        bg: "#29253E",
        border: "#6C5BB933",
        actionText: "Suche stoppen",
        actionTextColor: "#9B87F5",
      };
    }
  }, [isError, isLoading]);

  if (!isLoading && !isError) return null;

  return (
    <StatusContainer $bg={statusData.bg} $border={statusData.border}>
      <StatusRow>
        <span className="statusText">Status:</span>
        {isLoading && <span>{`Wir durchsuchen gerade ${brand}`}</span>}
        {isError && <span>Leider, konnten wir keine Zitate finden.</span>}
      </StatusRow>
      <StatusAction
        $color={statusData.actionTextColor}
        onClick={() => {
          if (isLoading) onCancel();
          if (isError) onNewSearch();
        }}
      >
        {statusData.actionText}
      </StatusAction>
    </StatusContainer>
  );
}

const StatusContainer = styled.div<{
  $bg: string;
  $border: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ $bg }) => $bg};
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: ${({ $border }) => $border};
  border-radius: 0px 0px 5px 5px;

  padding: 0.625rem 0.9375rem;
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  span {
    font-size: 0.625rem;
    line-height: 1rem;
    font-weight: 400;
    color: #e5e5e2;
  }

  .statusText {
    font-weight: 700;
  }
`;

const StatusAction = styled.div<{ $color: string }>`
  weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  color: ${({ $color }) => $color};
  cursor: pointer;
`;

export const Content = styled.div`
  width: 31.5rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: start;
  gap: 0.5rem;
  margin-bottom: 1.0625rem;
`;

export const Bubble = styled.div`
  padding: 1.0625rem 1.375rem;
  padding-bottom: 0;
  background-color: #292826;
  border-radius: 0.3125rem 0.3125rem 0 0;
  max-height: 18.9375rem;
  overflow: auto;
`;

export const FalseCitation = styled.div`
  background-color: #202020;
  padding: 0.6875rem 1rem;
  color: rgba(255, 255, 255, 0.2);
  font-size: 0.625rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
`;

export const SourceDisplay = styled.div`
  width: 100%;
  margin-bottom: 0.9375rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.625rem;
`;

export const CitationDisplay = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 0.75rem;
`;

export const SourceDisplayInput = styled.textarea`
  width: 100%;
  margin-bottom: 0.9375rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.625rem;
  background-color: transparent;
  font-family: inherit;
  resize: none;
`;

export const CitationDisplayInput = styled.textarea`
  width: 100%;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 0.75rem;
  background-color: transparent;
  font-family: inherit;
  resize: none;
`;
