import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function findCitation(
  query: string,
  source: "beck" | "rechtssprechung",
  signal: AbortSignal
): Promise<{
  output: {
    zitates: any[];
  };
  metadata: any;
}> {
  const resp = await axiosWithAuth.post(
    `${process.env.REACT_APP_NOORIOO_API}/citation/find`,
    {
      query,
      source,
    },
    { signal }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
