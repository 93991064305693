import axiosWithAuth from "lib/utils/axiosWithAuth";

export default async function syncHomeWork(
  id: string,
  content: string
): Promise<{ ok: boolean }> {
  const resp = await axiosWithAuth.post(
    `${process.env.REACT_APP_NOORIOO_API}/documents/content/${id}`,
    {
      content,
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }
  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
